import React from 'react';
import { makeStyles, Grid, List, ListItem, ListItemText, Chip, FormControl, TableCell, TableRow, TableBody, TableContainer, Table, TableHead, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config';
import axios from 'axios';
import './admin.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchBar from "material-ui-search-bar";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

}));

export default function SettlementEvent() {
    const params = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [eventList, SetEventList] = React.useState([]);
    


    const [eventType, SetEventType] = React.useState(params.event_type);
    const [fetchComplete, setFetchComplete] = React.useState(false);
    React.useEffect(() => {
        window.scroll(0, 0);
        getSettlementEventTypes();
    }, []);

    const [tempEventList, SetTempEventList] = React.useState([]);
    const [searched, setSearched] = React.useState("");

    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    function getSettlementEventTypes() {
        setFetchComplete(true);
        const data = JSON.stringify({

            "event_type": eventType,
            "status": "Open"

        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/getSettlementEvents`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // //console.log(JSON.stringify(response.data));
                SetEventList(response.data.resultData);
                SetTempEventList(response.data.resultData);
            })
            .catch(function (error) {
                console.log(error);
            }).then(() => { setFetchComplete(false); });
    }

    const requestSearch = (searchedVal) => {
        // console.log("searchedVal",searchedVal);
        const filteredRows = eventList.filter((row) => {
            return row.event_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        SetTempEventList(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    return (
        <div className={classes.root}>
            <Header />
            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="Settlement-section">
                    <div style={{ width: '100%' }}>
                        <div className="datatable_heading">
                            Event Settlement
                        </div>


                    </div>
                    <div style={{ width: '100%', overflow: 'scroll' }}>

                        {fetchComplete ? <div className="set-loader"><CircularProgress /> </div> :
                            <>
                                <SearchBar
                                    value={searched}
                                    onChange={(searchVal) => requestSearch(searchVal)}
                                    onCancelSearch={() => cancelSearch()}
                                />
                                <Table className="responsive_table" size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>

                                            <TableCell component="th" align="left">S NO.</TableCell>
                                            <TableCell align="left" component="th" >Name</TableCell>
                                            <TableCell align="left" component="th" >Date</TableCell>
                                            {/* <TableCell align="left" component="th" >status</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            tempEventList.map((row, index) => (
                                                <TableRow key={index}>

                                                    <TableCell align="left">{index + 1}</TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            <div >
                                                                <Link onClick={() => history.push(`/settlement-data/${row.event_type}/${row.event_id}`)}>{row.event_name}</Link></div>
                                                            {row.totalBets > 0 ? <div style={{ marginLeft: '10px' }}><Chip label={row.totalBets} size="small" style={{ backgroundColor: '#008000a1', color: 'white' }} /></div> : ''}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            new Date(row.open_date).toLocaleString()
                                                        // moment(row.open_date, "MM/DD/YYYY hh:mm:ss A").format('DD MMM YYYY  hh:mm:ss A')
                                                        }
                                                    </TableCell>
                                                    {/* <TableCell align="left">
                                                <Chip size="small" label={row.status} color={row.status === 'Open' ? "primary" : "secondary"} />
                                            </TableCell> */}
                                                </TableRow>
                                            ))
                                        }


                                    </TableBody>
                                </Table>
                            </>
                        }
                    </div>
                </Grid>
            </Grid>
            <Footer />

        </div >
    );
}