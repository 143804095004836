import * as React from 'react';
import { makeStyles, Grid, List, ListItem, ListItemText, DialogTitle, Dialog, DialogActions, DialogContentText, DialogContent, Chip, Switch, MenuItem, InputLabel, FormControlLabel, Select, Button, FormControl, TableCell, TableRow, TableBody, TableContainer, Table, TableHead, Checkbox, TextField } from '@material-ui/core';
import Header from './Header'
import Footer from './Footer'
import './admin.css';
import { useParams } from 'react-router-dom';
import Appconfig from '../config/config';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchBar from "material-ui-search-bar";
import { ToastContainer, toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


export default function UnsettledBets() {
    const classes = useStyles();
    const { event_id, event_name } = useParams();
    const [fetchComplete, setFetchComplete] = React.useState(false);
    const [unsettleBetList, setUnsettleBetList] = React.useState([]);
    const [searched, setSearched] = React.useState("");
    const [selectedBets, setSelectedBets] = React.useState([]);

    // console.log('selectedBets',selectedBets);

    const handleSelect = (id) => {
        setSelectedBets((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((betId) => betId !== id) // Uncheck
                : [...prevSelected, id] // Check
        );
    };

    const handleSelectAll = () => {
        if (selectedBets.length === unsettleBetList.length) {
            setSelectedBets([]); // Unselect all
        } else {
            setSelectedBets(unsettleBetList.map((row) => row._id)); // Select all
        }
    };

    const requestSearch = (searchedVal) => {
        // console.log("searchedVal",searchedVal);
        const filteredRows = unsettleBetList.filter((row) => {
            return row.event_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setUnsettleBetList(filteredRows);
    };


    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    React.useEffect(() => {
        getAllUnsettledBets();
    }, []);

    const getAllUnsettledBets = async () => {
        setFetchComplete(true); // Start loading state

        try {
            const data = JSON.stringify({ match_id: event_id });

            const config = {
                method: 'post',
                url: `${Appconfig.apiUrl}betting/getOpenBetsByOperator`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            };

            const response = await axios(config);

            if (response.data.result) {
                let unSettledList = response.data.resultData;
                setUnsettleBetList(unSettledList);
            }
        } catch (error) {
            console.error("Error fetching unsettled bets:", error);
        } finally {
            setFetchComplete(false); // Ensure loading state is reset
        }
    };

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    const settledBetings = async () => {
        if (selectedBets.length === 0) {
            notify('Please select at least one bet', 'error');
            return;
        }

        const checkPass = window.prompt("Please Enter Password");
        const password = "King@123";

        if (checkPass !== password) {
            notify('Please Enter Correct Password', 'error');
            return;
        }

        try {
            const config = {
                method: 'post',
                url: `${Appconfig.apiUrl}settlement/unsettledBetSettleAgain`,
                headers: { 'Content-Type': 'application/json' },
                data: selectedBets
            };

            const response = await axios(config);

            if (response.data.result) {
                notify(response.data.resultMessage, 'success');
                getAllUnsettledBets();
            } else {
                notify(response.data.resultMessage, 'error');
            }
        } catch (error) {
            console.error("Error:", error);
            notify('An error occurred while processing the request.', 'error');
        }
    };

    const deleteBetings = async () => {
        if (selectedBets.length === 0) {
            notify('Please select at least one bet', 'error');
            return;
        }

        const checkPass = window.prompt("Please Enter Password");
        const password = "King@123";

        if (checkPass !== password) {
            notify('Please Enter Correct Password', 'error');
            return;
        }

        try {
            const config = {
                method: 'post',
                url: `${Appconfig.apiUrl}betting/deleteBetsByOperator`,
                headers: { 'Content-Type': 'application/json' },
                data: selectedBets
            };

            const response = await axios(config);

            if (response.data.result) {
                notify(response.data.resultMessage, 'success');
                getAllUnsettledBets();
            } else {
                notify(response.data.resultMessage, 'error');
            }
        } catch (error) {
            console.error("Error:", error);
            notify('An error occurred while processing the request.', 'error');
        }
    };


    return (
        <div className={classes.root}>
            <Header />
            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>
                <Grid item lg={10} xs={12} className="Settlement-section">
                    <div style={{ width: '100%' }}>
                        <div className="datatable_heading">
                            {event_name ? event_name : 'Unsettled Bets'}
                            <div style={{ "marginLeft": "1rem", }} onClick={() => settledBetings()}>
                                <span class="lable-user-name" style={{
                                    "padding": "5px", "border": "2px solid", width: "75px",
                                    display: "block"
                                }}>
                                    Settle Bets
                                </span>
                            </div>
                            <div style={{ "marginLeft": "1rem", }} onClick={() => deleteBetings()}>
                                <span class="lable-user-name" style={{
                                    "padding": "5px", "border": "2px solid", width: "80px",
                                    display: "block"
                                }}>
                                    Delete Bets
                                </span>
                            </div>
                        </div>

                    </div>
                    <div style={{ width: '100%', overflow: 'scroll' }}>
                        {fetchComplete ? <div className="set-loader"><CircularProgress /> </div> :
                            <>
                                {/* <SearchBar
                                value={searched}
                                onChange={(searchVal) => requestSearch(searchVal)}
                                onCancelSearch={() => cancelSearch()}
                            /> */}
                                <Table className="responsive_table" size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Checkbox
                                                    indeterminate={
                                                        selectedBets.length > 0 &&
                                                        selectedBets.length < unsettleBetList.length
                                                    }
                                                    checked={selectedBets.length === unsettleBetList.length}
                                                    onChange={handleSelectAll}
                                                />
                                            </TableCell>
                                            <TableCell align="left" component="th" >Round Id</TableCell>
                                            <TableCell align="left" component="th" >Place Name</TableCell>
                                            <TableCell align="left" component="th" >Bet Type</TableCell>
                                            <TableCell align="left" component="th" >Date</TableCell>
                                            <TableCell align="left" component="th" >Type</TableCell>
                                            <TableCell align="left" component="th" >Stake</TableCell>
                                            <TableCell align="left" component="th" >Price Val</TableCell>
                                            <TableCell align="left" component="th" >Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            unsettleBetList.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        <Checkbox
                                                            checked={selectedBets.includes(row._id)}
                                                            onChange={() => handleSelect(row._id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            {row?._id}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            {row?.place_name}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            {row?.betting_type}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            {new Date(row?.createdAt).toLocaleString()}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            {row?.type ? "Back" : "Lay"}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            {row?.stake}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            {row?.price_val.toFixed(2)}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            {row?.status}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </>
                        }
                    </div>
                </Grid>
            </Grid>
            <Footer />

        </div >
    );
}
