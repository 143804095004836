export default {
    apiUrl: 'https://digital.allcasino.in:3000/',
    // apiUrl: 'http://127.0.0.1:3000/',

    bUrl: 'http://139.162.213.154:3000/',
    userTypes: ['Super Admin', 'Master Admin', 'Admin', 'Super Master', 'Master', 'Agent', 'User'],
    userTypesAlias: {
        'super_admin': 'Super Admin', 'master_admin': 'Master Admin', 'admin': 'Admin', 'super_master': 'Super Master', 'master': 'Master', 'agent': 'Agent', 'user': 'User'
    },
    userTypesSign: {
        'Super Admin': 'Super Admin', 'Master Admin': 'Master Admin','Admin': 'Admin','Super Master': 'Super Master (SMDL)','Master': 'Master (MDL)', 'Agent': 'Agent (DL)', 'User': 'User'
    },
    sitecodes: 'P5',
    videoUrl: {
        '7ud': ' http://139.162.213.154/dvideo/teen20.html',
        'ab': ' http://139.162.213.154/dvideo/teen20.html',
        'ltp': ' http://139.162.213.154/dvideo/teen20.html',
        't20': ' http://139.162.213.154/dvideo/teen20.html',
        'dt20': ' http://139.162.213.154/dvideo/teen20.html',
        'aaa': ' http://139.162.213.154/dvideo/teen20.html',
        '32c': 'http://45.56.112.18/?id=3084',
    }
    // videoUrl: {
    //     '7ud': 'http://45.56.112.18/?id=3081',
    //     'ab': 'http://45.56.112.18/?id=3082',
    //     'ltp': 'http://45.56.112.18/?id=3080',
    //     't20': 'http://45.56.112.18/?id=3085',
    //     'dt20': 'http://45.56.112.18/?id=3087',
    //     'aaa': 'http://45.56.112.18/?id=3086',
    //     '32c': 'http://45.56.112.18/?id=3084',
    // }
    // http://139.162.213.154/dvideo/teen20.html
    ,
    marketSarket: "http://marketsarket.in:3002/"
}
export const SOCKET_URL = 'wss://ws.operator.buzz:7072/';
