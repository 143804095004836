
import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'

// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useParams, useHistory } from "react-router-dom";

import './admin.css';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function BetDeleteEvents() {
    const history = useHistory();
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const params = useParams();
    const [event_id, setEventId] = React.useState(params.event_type_id);
    const [EventMatchList, setEventMatchList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [EventMatchId, setEventMatchId] = React.useState('');

    useEffect(() => {
        eventMatches(params.event_type_id)
    }, [])

    const columns = [

        {
            field: 'id',
            headerName: 'S.NO',
            editable: true,
            width: 58
        },
        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            width: 200,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    <Link to='#' onClick={() => history.push(`/betdelete-bet/${params.event_type_id}/${rowData.row.event_id}`)} >{rowData.row.event_name}</Link>
                );
            }
        },
        {
            field: 'open_date',
            headerName: 'Date',
            sortable: false,
            width: 200,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    <div >{new Date(rowData.row.open_date).toLocaleString()}</div>
                );
            }
        }
    ];

    function eventMatches(event_type) {
        let tmpEventList;
        setEventMatchId(event_type);
        var data = JSON.stringify({
            "user_id": userInfo._id,
            event_type_id: event_type
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/blockEvents`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setEventMatchList(response.data.resultData);
                tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((EventMatchList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('newsss', response.data.resultData);
                setEventMatchList(tmpEventList)

                //  console.log(EventList);


                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            }).then(() => {
                if (!tmpEventList.length) {
                    document.getElementsByClassName("MuiDataGrid-window")[0].innerHTML = "<div class='no-row'><span>No Rows</span></div>"
                }

            });


    }
    function blockUnblockEvent(event_id) {

        var data = JSON.stringify({
            "user_id": userInfo._id,
            "event_id": event_id,
            "market_id": "0",
            "fancy_id": "0",
            "event_type_id": params.event_type_id,
            "type": "Event"

        });
        console.log('send string', data);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/block&unblockMarket`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log('blockUnblockEvent string', response);
                eventMatches(params.event_type_id)

            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Header />
            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="report-section">

                    <div className="datatable_heading">
                        Event Listing

                    </div>
                    <div style={{ display: 'flex', height: '100vh' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                className="block-market"
                                // components={{
                                //     Toolbar: GridToolbar,
                                // }}
                                // filterModel={{
                                //     items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                                // }}
                                rows={EventMatchList}
                                columns={columns}
                                pageSize={100}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>

                </Grid>
            </Grid>

            <Footer />
        </div >
    );
}